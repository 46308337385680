<template>
  <div class="register_wrap">
    <div class="top">
      <div class="top_inner">
        <div class="df_box">
          <div class="logo" @click="$router.push('/index/home')">
            <img :src="logo" alt="" />
          </div>
          <span>欢迎注册</span>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="inner">
        <div class="left">
          <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="经营类型：" prop="membership_type_id">
              <el-radio-group v-model="form.membership_type_id">
                <el-radio v-for="item in typelist" :key="item.id" :label="item.id" style="margin-bottom: 10px">
                  {{ item.title }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="所在地区：" prop="area">
              <el-cascader style="width: 376px" placeholder="请选择省市区" v-model="form.area" :options="options" :props="{ expandTrigger: 'hover', label: 'text' }"></el-cascader>
            </el-form-item>
            <el-form-item label="单位名称：" prop="organization_name">
              <el-input v-model="form.organization_name" placeholder="请填写单位名称"></el-input>
            </el-form-item>
            <el-form-item label="用户账号：" prop="uname">
              <el-input v-model="form.uname" autocomplete="new-password" placeholder="请填写用户账号"></el-input>
            </el-form-item>
            <el-form-item label="账号密码：" prop="password">
              <el-input type="password" v-model="form.password" autocomplete="new-password" placeholder="请填写账号密码"></el-input>
            </el-form-item>
            <el-form-item label="真实姓名：" prop="lxr">
              <el-input v-model="form.lxr" placeholder="请填写真实姓名"></el-input>
            </el-form-item>
            
            <el-form-item label="手机号码：" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="vcode" style="margin-bottom: 5px">
              <div class="df_box">
                <el-input style="width: 170px" v-model="form.vcode" placeholder="请输入验证码">
                  <img slot="prefix" src="@/assets/img/login/yanzheng.png" style="width: 17px; height: 20px; margin: 0 6px" />
                </el-input>
                <div class="btn" @click="handle">发送验证码</div>
              </div>
            </el-form-item>
            <el-form-item label="推荐人：" >
              <el-input v-model="form.inviter" placeholder="请输入推荐人"></el-input>
            </el-form-item>

            <el-form-item>
              <div class="df_box">
                <div class="agreen_img" @click="sel = !sel">
                  <img v-if="sel" src="@/assets/img/login/sel.png" />
                  <img v-else src="@/assets/img/login/sel_nor.png" />
                </div>
                <p class="agreen">我已阅读并接受<span @click="openAgreen(1)">《用户协议》</span>及<span @click="openAgreen(2)">《隐私协议》</span></p>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="btn reg_btn" @click="onSubmit">注册</div>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div class="login">已有账号？请<span @click="$router.push('/login')">点击登录</span></div>
          <div class="gift_box">
            <img src="@/assets/img/login/gift.png" style="width: 98px; height: 67px" />
            <p class="gift">注册送新人大礼包</p>
          </div>
          <div class="cont">
            <p>只要您是合法经营的药店、诊所、医院、门诊部或医药公司，</p>
            <p>在{{ info.xsdwmc }}注册并认证后，可享受以下服务:</p>
            <ul>
              <li>在{{ info.xsdwmc }}购买合法经营的商品</li>
              <li>享受一站式药品采购服务</li>
              <li>获得平台的活动补贴</li>
              <li>参与平台的促销活动</li>
            </ul>
          </div>
          <div>
            <p class="contaxt">联系我们：</p>
            <div class="df_box">
              <div class="item">
                <img src="@/assets/img/login/QQ.png" style="width: 24px; height: 24px; margin-right: 10px" />
                <span>{{ info.lxqq }}</span>
              </div>
              <div class="item">
                <img src="@/assets/img/login/phone.png" style="width: 24px; height: 24px; margin-right: 10px" />
                <span>{{ info.site_fwdh }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="inner">
        <div class="flex_sp_c">
          <!-- <p>备案号：蜀ICP备2022016240号-1</p> -->
          <p>{{ info.name_site }}</p>
        </div>
        <div class="flex_sp_c">
          <p>公司地址：{{ info.xsdwdz }}</p>
          <p>联系电话:{{ info.site_fwdh }}</p>
        </div>
        <div class="flex_sp_c">
          <p>{{ info.company_ts }}</p>
          <p>{{ info.copy_right }}</p>
        </div>
        <div class="flex_sp_c">
          <p>{{ info.company_sm }}</p>
          <p>
            技术支持：<span style="color: #b2b4c4">{{ info.xsdwmc }}</span>
          </p>
        </div>
      </div>
    </div>

    <el-dialog title="请滑动验证码" :close-on-click-modal="false" :visible.sync="dialogVisible" width="440px" v-if="dialogVisible">
      <div style="margin-bottom: 40px">
        <drag @onValidation="onValidation" />
      </div>
    </el-dialog>

    <el-dialog :title="type == 1 ? '用户协议' : '隐私协议'" :visible.sync="agreenVisible" center width="80%">
      <div v-html="content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button class="custombtn" type="primary" @click="agreenVisible = false">我已阅读</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import drag from "@/components/drag.vue";
import md5 from "md5";
export default {
  components: {
    drag,
  },
  data() {
    return {
      logo: "",
      typelist: [],
      sel: true,
      dialogVisible: false,
      agreenVisible: false,
      type: 1,
      content: "",
      form: {
        uname: "", //用户账号
        password: "", //账号密码 MD5加密
        membership_type_id: "", //经营类型
        organization_name: "", //单位名称
        lxr: "", //联系人姓名
        inviter: "", //推荐人
        p_id: "",
        c_id: "",
        a_id: "",
        area: "",
        phone: "", //联系手机号
        vcode: "", //手机验证码
      },
      options: [],
      info: {},
      rules: {
        membership_type_id: [{ required: true, message: "请选择经营类型", trigger: "change" }],
        organization_name: [{ required: true, message: "请填写单位名称", trigger: "blur" }],
        uname: [{ required: true, message: "请填写用户账号", trigger: "blur" }],
        password: [{ required: true, message: "请填写账号密码", trigger: "blur" }],
        lxr: [{ required: true, message: "请填写真实姓名", trigger: "blur" }],
        // inviter: [{ required: true, message: "请输入推荐人", trigger: "blur" }],
        area: [{ required: true, message: "请选择省市区", trigger: "change" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        vcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$api("login.getType").then((res) => {
      this.typelist = res.data;
    });
    this.$api("login.getCity").then((res) => {
      this.options = res.data;
    });
    this.$api("home.getNavInfo").then((res) => {
      this.logo = res.data.settingImg.wz_logo;
    });
    this.$api("home.getFoot").then((res) => {
      this.info = res.data;
    });
  },
  methods: {
    onSubmit() {
      if (!this.sel) {
        this.$message.error("请先同意用户协议");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let p = {
            ...this.form,
            password: md5(this.form.password),
            p_id: this.form.area[0],
            c_id: this.form.area[1],
            a_id: this.form.area[2],
          };
          this.$api("login.register", p).then(() => {
            this.$message.success("注册成功");
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          });
        }
      });
    },
    openAgreen(type) {
      this.type = type;
      this.agreenVisible = true;
      this.$api("login.agreement", { lx: type }).then((res) => {
        this.content = res.data.content;
      });
    },
    handle() {
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      this.dialogVisible = true;
    },
    onValidation() {
      this.getcode();
      setTimeout(() => {
        this.dialogVisible = false;
        this.$notify({
          title: "提示",
          message: "短信已发送，注意查收",
          type: "success",
        });
      }, 1000);
    },
    getcode() {
      this.$api("login.getCode", {
        code_type: "103",
        phone: this.form.phone,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custombtn {
  background-color: @themeColor;
  border-color: @themeColor;
}
.custombtn:hover,
.custombtn:focus {
  background-color: @themeColor;
  border-color: @themeColor;
}
.register_wrap {
  width: 100%;
  height: 100%;
  min-width: 1220px;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px 0 22px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 400;
    color: #666;
    background-color: #fff;
    .top_inner {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .df_box {
        display: flex;
        align-items: center;
      }
    }
    .logo {
      width: 179px;
      height: 36px;
      margin-right: 30px;
    }
  }
  .center {
    flex: 1;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner {
      width: 1200px;
      height: 696px;
      background: #ffffff;
      box-shadow: 0px 0px 50px 0px #f5f6fa;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .left {
        max-width: 720px;
        margin-left: 50px;
        .el-input {
          width: 376px;
        }
        .df_box {
          display: flex;
          align-items: center;
        }
        .agreen_img {
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .agreen {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          span {
            color: @themeColor;
            cursor: pointer;
          }
        }
        .btn {
          width: 110px;
          height: 40px;
          background: @themeColor;
          border-radius: 4px;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .reg_btn {
          width: 375px;
          height: 50px;
          font-size: 20px;
          margin: 0;
        }
      }
      .right {
        .login {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          span {
            color: @themeColor;
            cursor: pointer;
          }
        }
        .gift_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 39px;
        }
        .gift {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-top: 19px;
          margin-bottom: 50px;
        }
        .cont {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 43px;
        }
        .contaxt {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin: 38px 0;
        }
        .df_box {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          .item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .bottom {
    background: #2c2f3a;
    width: 100%;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    font-size: 14px;
    font-weight: 400;
    color: #fefefe;
    .inner {
      width: 1200px;
    }
  }
}
</style>

<style lang="less">
.el-radio__input.is-checked + .el-radio__label {
  color: @themeColor!important;
}
.el-radio__input.is-checked .el-radio__inner {
  background: @themeColor !important;
  border-color: @themeColor !important;
}
</style>
